import clientHttp from '../tools/clientHttp'
import { fetchWithCache, saveToCache } from '../tools/sessionCache'
const URL = "/kits";
const URL_KITS_ITEMS = "/kits/all-kits-items";
let allKits = [];
let kitsItems = [];
let kitsCache = [];

export const getKitsCache = async () => {
    const { data, dataCached } = await fetchWithCache(URL_KITS_ITEMS);
    if(kitsCache.length === 0 || !dataCached) {
        kitsCache = data;
    }
    
    return kitsCache;
}

export const saveKits = async (kits) => {
    return clientHttp.post(`${URL}`, kits)
}

export const getKits = async () => {
    if(allKits.length === 0) {
        const { data } = await clientHttp.get(`${URL}`);
        allKits = data;
        kitsItems = data.map(kit => ({
            text: `${kit.ref} - CENTRO DE COSTO: ${kit.costCenter === "All" ? "TODOS" : kit.costCenter}`,
            value: kit.id
        }));
    }
    return { allKits, kitsItems };
}

export const getKitById = async (id) => {
    const { data } = await clientHttp.get(`${URL}/${id}`)
    return data
}

export const getKitsByStatus = async (status) => {
    const { allKits } = await getKits();
    return allKits.filter(kit => kit.status.toLowerCase() === status.toLowerCase());
}

export const getKitsItems = async () => {
    const { kitsItems } = await getKits();
    return kitsItems;
}

export const getKitsByEmployee = async (employee) => {
    const kistFilter = []
    const { allKits } = await getKits();
    allKits.forEach(kit => {
        kit.items.forEach(item => {
            if(genderMatch(item, employee) && costCenterMatch(item, employee) && positionMatch(item, employee)) {
                kistFilter.push(kit)
            }
        })
    })
    console.log(kistFilter)
    return kistFilter.map(kit => {
        return {
            text: kit.ref,
            value: kit.id
        }
    })
}

export const getKitsByEmployeeCache = async (employee) => {
    const kitsCache = await getKitsCache();
    const kitsCoincidence = kitsCache.filter(kit => kit.costCenter === employee.costCenter);
    if(kitsCoincidence.length === 0) return null
    const kitsItemsCoincidence = kitsCoincidence.filter(kit => kit.items.some(item => positionMatch(item, employee) && genderMatch(item, employee)));
    if(kitsItemsCoincidence.length === 0) return null
    return kitsItemsCoincidence[0]
}

const genderMatch = (kit, employee) => {
    return employee.gender.toLowerCase() === kit.sex.toLowerCase() || kit.sex === "U"
}

const costCenterMatch = (kit, employee) => {
    return employee.costCenter === kit.costCenter || kit.costCenter === "All"
}

const positionMatch = (kit, employee) => {
    return employee.position.toLowerCase() === kit.position.toLowerCase() || kit.position.toLowerCase() === "all"
}