import { fetchWithCache, saveToCache } from '../tools/sessionCache'
const URL = "/employs";
let employeesItems = [];

export const updatedCacheEmployees = async (data) => {
    await saveToCache(btoa(URL), data);
}

export const getEmployees = async () => {   
    const { data, dataCached } = await fetchWithCache(URL);
    if(employeesItems.length === 0 || !dataCached){        
        employeesItems = data.map(employee => ({
            text: `${employee.rut} - ${employee.firstName} ${employee.fatherName} ${employee.motherName}`,
            value: employee.id
        }))
    }
    return {
        employees: data, employeesItems
    }
}

export const getEmployeesItems = async () => {
    return employeesItems.length > 0 ? employeesItems : (await getEmployees()).employeesItems;
}

export const getCostCenters = async () => {
    const employees = (await getEmployees()).employees;
    const costCenters = [...new Set(employees.map(employee => employee.costCenter))];
    const sortedCostCenters = costCenters.sort((a, b) => a.localeCompare(b));
    return [
        { text: "TODOS", value: "All" },
        ...sortedCostCenters.map(costCenter => ({
            text: costCenter,
            value: costCenter
        }))
    ];
}

export const getPositions = async () => {
    const employees = (await getEmployees()).employees;
    const positions = [...new Set(employees.map(employee => employee.position))];
    const sortedPositions = positions.sort((a, b) => a.localeCompare(b));
    return [
        ...sortedPositions.map(position => ({
            text: position,
            value: position
        }))
    ];
}

export const getEmployeesByPosition = async (position) => {
    const employees = (await getEmployees()).employees;
    const filteredEmployees = employees.filter(employee => employee.position.toLowerCase() === position.toLowerCase());
    return filteredEmployees.map(employee => ({
        text: `${employee.rut} - ${employee.firstName} ${employee.fatherName} ${employee.motherName}`,
        value: employee.id
    }));
}

export const getEmployeesByCostCenter = async (costCenter) => {
    if(costCenter === "All") return (await getEmployees()).employeesItems;
    const employees = (await getEmployees()).employees;
    const filteredEmployees = employees.filter(employee => employee.costCenter.toLowerCase() === costCenter.toLowerCase());
    return filteredEmployees.map(employee => ({
        text: `${employee.rut} - ${employee.firstName} ${employee.fatherName} ${employee.motherName}`,
        value: employee.id
    }));
}

export const getFilteredEmployees = async (filters = {}) => {
    const { employees } = await getEmployees();
    
    return employees.filter(employee => {
        return Object.entries(filters).every(([key, value]) => {
            if (value === undefined || value === null || value === '') return true;
            if (key === 'costCenter' && value === 'All') return true;
            if (key === 'gender' && value === 'U') return true;
            const employeeValue = employee[key];
            if (employeeValue === null || employeeValue === undefined) return false;
            return employee[key].toLowerCase() === value.toLowerCase();
        });
    }).map(employee => ({
        text: `${employee.firstName} ${employee.fatherName} ${employee.motherName}`,
        value: employee.id,
        costCenter: employee.costCenter,
        position: employee.position,
        gender: employee.gender
    }));
}